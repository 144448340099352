import React from "react";
import PT from "prop-types";
import get from "lodash/get";
import { CollectionName } from "../../atoms";
import {
  StoryCardWithoutImage,
  HorizontalCardWithImage4x3,
  StoryCardWithImage,
  HorizontalStoryCard
} from "../../molecules";
import { DfpAd } from "../../dfp-ad/index";
import { getCollectionName } from "../../helper/utils";
import "./three-col-four-story-with-ad.m.css";

export const ThreeColFourStoryWithAd = ({ collection }) => {
  const [firstStory, secondStory, thirdStory, ...restOftheStories] = get(collection, ["items"], []).filter(
    item => item.type === "story"
  );

  return (
    <div className="container">
      <CollectionName name={getCollectionName(collection)} />
      <div styleName="wrapper">
        {firstStory && (
          <div styleName="bordered">
            <StoryCardWithoutImage story={firstStory.story} lines={4} />
          </div>
        )}

        {/* 1st Row & 2nd Column */}
        {secondStory && (
          <div styleName="two-col-card bordered">
            <HorizontalStoryCard story={secondStory.story} lines={3} />
          </div>
        )}

        {/* 2nd Row & 1st Column */}
        {thirdStory && (
          <div styleName="bordered">
            <StoryCardWithImage story={thirdStory.story} lines={2} />
          </div>
        )}

        {/* 2nd Row & 2nd Column */}
        {restOftheStories &&
          restOftheStories.length && (
            <div styleName="bordered horizontal-card">
              {restOftheStories.slice(0, 3).map((item, index) => {
                return <HorizontalCardWithImage4x3 story={item.story} key={index} lines={3} />;
              })}
            </div>
          )}

        {/* 2nd Row & 3rd Column */}
        <div styleName="bordered ad-section" className="dfp-ad-height-280">
          <DfpAd adtype="FortuneIndia_RHS_2" />
        </div>
      </div>
    </div>
  );
};

ThreeColFourStoryWithAd.propTypes = {
  collection: PT.object.isRequired
};
